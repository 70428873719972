import React from "react"

import SEO from "../../components/seo"
import Layout from "../../components/Layouts/Base"
import Sidebar from "../../components/Integrations/Sidebar"
import YoutubeEmbed from "../../components/YoutubeEmbed"

import headerImg from "../../images/integrations/webstarts/header.png"
import img1 from "../../images/integrations/webstarts/1.png"
import img2 from "../../images/integrations/webstarts/2.png"
import img3 from "../../images/integrations/webstarts/3.png"
import img4 from "../../images/integrations/webstarts/4.png"
import img5 from "../../images/integrations/webstarts/5.png"
import img6 from "../../images/integrations/webstarts/6.png"
import img7 from "../../images/integrations/webstarts/7.png"

export const query = graphql`
  query WebStartsIntegrationQuery {
    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      skip: 0
      limit: 100
      filter: { showInSidebar: { eq: true } }
    ) {
      edges {
        node {
          slug {
            current
          }
          title
        }
      }
    }
  }
`

const IntegrationWebStartsPage = ({ data }) => {
  const posts = data.allSanityPost.edges

  return (
    <Layout>
      <SEO
        title="Accept Payments & Subscriptions using WebStarts with Payhere"
        description={
          "Create payment links to get paid online. Launch a one-page website with payments built in. Embed or integrate with 1000s of platforms using our SDKs or Zapier integration."
        }
        keywords={[
          `payments`,
          `online payments`,
          `card payments`,
          `recurring payments`,
          `subscriptions`,
          `recurring donations`,
          `donations`,
          `stripe`,
        ]}
      />

      <div className="max-w-5xl mx-auto px-6 py-10 lg:pb-16">
        <div className="md:grid md:grid-cols-12 md:gap-10">
          <Sidebar posts={posts} />
          <div className="md:col-span-8">
            <h1 className="text-2xl font-display text-black font-bold leading-8 md:text-4xl sm:leading-tight">
              Accept Payments & Subscriptions using WebStarts with Payhere
            </h1>

            <div className="prose mt-6">
              <img src={headerImg} className="" />

              <YoutubeEmbed embedId="ZZTqmO7hcIk" />

              <p>
                Creating a website on WebStarts and need to integrate payments?
              </p>
              <p>There are two ways that this can be easily done.</p>
              <h2>1. Link via a button</h2>
              <p>
                First sign up and create a plan on Payhere. For this example we
                have used a dance school that wants to charge monthly for dance
                lessons.
              </p>

              <img src={img1} className="" />

              <p>
                Also at this stage you will need to add the link to where you
                want your customer directed after a successful purchase (this is
                under the advanced tab in our plan builder). In this case, we
                want the user to be directed back to your website to be
                displayed a message.
              </p>

              <img src={img2} className="" />

              <p>
                Once your plan is saved you will need to copy the URL of the
                page we have created for you (simply click copy link).
              </p>

              <img src={img3} className="" />

              <p>
                Now back to the WebStarts platform, once on your website, add a
                button and place on the page.
              </p>

              <img src={img4} className="" />

              <p>
                Within the button settings you can then add the link to the plan
                that we created for you in Payhere.
              </p>

              <img src={img5} className="" />

              <p>
                Once the site is published, your customers will be able to pay
                for your services.
              </p>

              <img src={img6} className="" />

              <h2>2. Iframe Embed</h2>
              <p>
                Within the WebStarts platform, click on add and scroll down to
                the bottom and select iFrame Embed. You will then simply paste
                the URL of your payment plan from Payhere into the box.
              </p>

              <img src={img7} className="" />

              <p>
                You will then have the payment form directly on your website.
                You can link a button on your website to this particular page or
                even have this as your pricing/ payment page. If you are
                choosing this option you will not need to have a URL pasted into
                the Success URL box as shown in option 1.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IntegrationWebStartsPage
